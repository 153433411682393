
import { Vue } from 'vue-class-component';
import authenticationService from '@/services/authentication.service';

export default class Authentication extends Vue {
  email = '';

  password = '';

  submitted = false;

  error = '';

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    const { email, password } = this;

    // stop here if form is invalid
    if (!(email && password)) {
      return;
    }

    try {
      authenticationService.login(email, password);
      if (authenticationService.isLoggedIn) {
        this.$router.push('/');
      } else {
        this.error = 'Invalid email or password';
      }
    } catch (error: any) {
      this.error = 'Unknown error on login';
    }
  }
}
